import { cachedGet, getApiClient, ttlCache } from '@troon/api-client';
import { createContext, Show, Suspense, useContext, createMemo } from 'solid-js';
import { getRequestEvent, isServer } from 'solid-js/web';
import { query, createAsync, redirect, useParams } from '@solidjs/router';
import type { AccessorWithLatest } from '@solidjs/router';
import type { ApiResponse } from '@troon/api-client';
import type { Accessor, ParentProps } from 'solid-js';

type User = ApiResponse<'get', '/v0/operator/auth/me'>;
export type Facilities = Exclude<ApiResponse<'get', '/v0/operator/facilities'>['facilities'], undefined>;

type CTX = AccessorWithLatest<{ user: User; facilities: Facilities } | null | undefined>;

const Root = createContext<CTX>();

export function RootContext(props: ParentProps) {
	const data = createAsync(
		async () => {
			const compare = new Intl.Collator('en-US').compare;
			const [user, facilities] = await Promise.all([getLoggedInUser(), getOperatorFacilities({})]);
			return user && facilities
				? {
						user,
						facilities: facilities.facilities.sort((a: Facilities[number], b: Facilities[number]) =>
							compare(a.facility.name, b.facility.name),
						),
					}
				: null;
		},
		{ deferStream: true },
	);

	return (
		<Suspense>
			<Show when={data() || data() === null}>
				<Root.Provider value={data}>{props.children}</Root.Provider>
			</Show>
		</Suspense>
	);
}

const getOperatorFacilities = cachedGet('/v0/operator/facilities', {}, { redirect401: false });

export const getLoggedInUser = query(async (notRequired: boolean = false): Promise<User | null> => {
	if (isServer) {
		return Promise.resolve(getRequestEvent()!.locals.user ?? null);
	}

	if (ttlCache?.has(getLoggedInUser.key)) {
		return ttlCache.get<User>(getLoggedInUser.key) ?? null;
	}

	try {
		const user = await cachedGet('/v0/operator/auth/me', {}, { redirect401: !notRequired })({});
		if (user) {
			getApiClient().isAuthenticated = true;
		}
		ttlCache?.set(getLoggedInUser.key, user);
		return user;
	} catch {
		ttlCache?.set(getLoggedInUser.key, null);
		return null;
	}
}, '/v0/operator/auth/me');

export function useUser(): Accessor<User | null | undefined> {
	const ctx = useContext(Root)!;
	return () => ctx()?.user;
}

export function useOperatorFacilities() {
	const ctx = useContext(Root)!;
	return () => ctx()?.facilities;
}

export function useFacility(slug: Accessor<string>) {
	const facilities = useOperatorFacilities();
	const facility = createMemo(() => facilities()?.find((f) => f.facility.slug === slug()));
	return facility;
}

export function useCurrentFacility() {
	const params = useParams<{ facility: string }>();
	return useFacility(() => params.facility);
}

export const requireLoggedIn = query(async () => {
	const user = await getLoggedInUser();
	if (user === null) {
		const url = isServer ? new URL(getRequestEvent()!.request.url) : new URL(window.location.toString());
		if (!url.searchParams.get('redirect')) {
			url.searchParams.set('redirect', url.pathname);
		}
		return redirect(`/auth/login?${url.searchParams.toString()}`);
	}
	return user;
}, 'require-logged-in');

export const requireLoggedOut = query(async (redirectPath?: string) => {
	const user = await getLoggedInUser(false);
	if (user) {
		return redirect(redirectPath ?? '/');
	}
	return null;
}, 'require-logged-out');
